import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import VueTippy from 'vue-tippy'
import CircleTabulation from '@/directives/CircleTabulation'
import TabOnEnter from '@/directives/TabOnEnter'
import TabOnArrows from '@/directives/TabOnArrows'
import DropdownKeysNavigation from '@/directives/DropdownKeysNavigation'

// import './theme.scss'
import {TokenService} from '@/services/storage_service';
import ApiService from "@/services/api_service";
import GlobalService from "@/services/global_service";
import "@/assets/sass/index.sass";
import 'tippy.js/dist/tippy.css'
// import 'tippy.js/themes/light.css'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import mitt from 'mitt'
import {IData} from '@/types'
import {useLocale} from '@/composables/useLocale'

const emitter = mitt();

const app = createApp(App);

//axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded"
app.config.globalProperties.$http = axios;

ApiService._axios.interceptors.request.use((config)=>{
  emitter.emit('start_loading');
  return config;
})
ApiService._axios.interceptors.response.use((config)=>{
  emitter.emit('end_loading');
  return config;
})

app.provide('emitter', emitter);

app
  .directive('circle-tabulation', CircleTabulation)
  .directive('tab-on-enter', TabOnEnter)
  .directive('tab-on-arrows', TabOnArrows)
  .directive('dropdown-keys-navigation', DropdownKeysNavigation)


if (TokenService.getToken()) {
   ApiService.setHeader()
}
ApiService.mount401Interceptor();

const extraParams:{
  //urlHash?:string
  location_pathname?: string
  location_search?: string
  location_hash?: string
} = {
  location_pathname: window.location.pathname,
  location_search: window.location.search,
  location_hash: window.location.hash

}

window['event_restore'] = function (id) {
  emitter.emit('event_restore', id)
}

window['tabs_man'] = {
  people: {
    confirmAndConvertCustomer(email, id) {
      emitter.emit('confirmAndConvertCustomer', {email, id})
    }
  }
}

// window['refreshBlockRemoveTest'] = function (attrs) {
//   store.commit('refreshBlockRemove', attrs)
// }

let tabUid = window.sessionStorage.getItem("disarea.tabUid")
if (!tabUid) {
  tabUid = Date.now() + '_' + Math.random()
  window.sessionStorage.setItem("disarea.tabUid", tabUid)
}
ApiService.setTabUid(tabUid)

// Default panels width
// store.commit('people/setLeftPanelWidth', 700)
// store.commit('ticket/setLeftPanelWidth', 810)

if (!window['disarea']) {
  window['disarea'] = {}
}

window['disarea'].openMyAccount = () => {
  emitter.emit('openMyAccount')
}


GlobalService.get_main(extraParams).then(async (response)=>{
  console.log('main.ts get_main response', response)

  // AVB: скрываем пока чекбоксы
  // response.model.settings['lw_multi-select'] = '0'

  console.log('isDebugMode', response.model?.config?.debug, process.env.VUE_APP_DOMAIN_TYPE, !!response.model?.config?.debug, process.env.VUE_APP_DOMAIN_TYPE !== 'release')

  const isDebugMode = !!response.model?.config?.debug && process.env.VUE_APP_DOMAIN_TYPE !== 'release'

  app.provide('isDebugMode', isDebugMode)
  window['disarea']['isDebugMode'] = isDebugMode

  //if (!response.model?.config?.debug) {

  app.config.errorHandler = (err, vm, info) => {
    const errObj = err as IData

    try {
      const isChunkLoadError = window['disarea'].isChunkLoadError(errObj)
      //alert(`index main.ts app.config.errorHandler isChunkLoadError: ${isChunkLoadError} | ${errObj.message}`)
      if (isChunkLoadError) {
        const dataForLog = {
          source: 'index main app.config.errorHandler',
          error: {
            name: errObj.name,
            message: errObj.message
          }
        }
        window['disarea'].reloadOnError('', dataForLog)
      } else {
        window['disarea']['errorLogger']['logData']({
          source: 'index main app.config.errorHandler',
          message: errObj?.message,
          info,
          stack: errObj?.stack,
        })
      }
    } catch (e) {
      console.log('index main app.config.errorHandler exception', e)
      if (window['disarea'] && window['disarea']['errorLogger'] && typeof window['disarea']['errorLogger']['logData'] === 'function') {
        window['disarea']['errorLogger']['logData']({'index main app.config.errorHandler exception': e.message})
      }
    }
/*
    if (window['disarea'] && window['disarea']['errorLogger'] && typeof window['disarea']['errorLogger']['logData'] === 'function') {
      window['disarea']['errorLogger']['logData']({
        source: 'app.config.errorHandler',
        message: errObj?.message,
        info,
        stack: errObj?.stack,
      })
    }
    const isChunkLoadError = typeof errObj.message === "string" && errObj.message.indexOf('Loading') !== -1 && errObj.message.indexOf('chunk') !== -1 && errObj.message.indexOf('failed') !== -1
    alert(`index main.ts app.config.errorHandler isChunkLoadError: ${isChunkLoadError} | ${errObj.name}`)
    if (isChunkLoadError) {
      alert('index main.ts app.config.errorHandler isChunkLoadError')
      //window.location.reload()
    }
*/
  }

  app.config.warnHandler = function(message, vm, trace) {
    if (window['disarea'] && window['disarea']['errorLogger'] && typeof window['disarea']['errorLogger']['logData'] === 'function') {
      window['disarea']['errorLogger']['logData']({
        source: 'app.config.warnHandler',
        message,
        trace,
      })
    }
  }

  //}

  if (window['disarea'] && window['disarea']['logger'] && typeof window['disarea']['logger']['setLoggerBaseData'] === 'function') {
    const requestParams = {
      site_ver: 'v2',
      c_srf: response.c_srf,
      tabUid,
    }
    const requestData = {
      site_ver: 'v2',
      site_lang: response?.model?.settings?.site_lang,
      authUser: {
        id: response?.model?.authUser?.id,
        name: response?.model?.authUser?.name
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#39;"),
        email: response?.model?.authUser?.email
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#39;"),
      }
    }
    window['disarea']['logger']['setLoggerBaseData'](requestParams, requestData)
  }

  if (response.Authorization) {
    store.commit('auth/loginSuccess', response.Authorization);
  }

  ApiService.setCSRF(response.c_srf)
  store.commit('auth/changeAuthUser', {authUser: response.model.authUser});
  store.commit('settings/setSettings', response.model.settings);


  // if (window['disarea']['isDebugMode']) {
  //   response.model.config.isProAccount = true
  // }

  store.commit('setConfig', response.model.config)

  store.commit('updateProject', {project:response.model.defProject});
  store.commit('board/set_projectId', response.model.defProject.id);
  store.commit('updateProjects', [...response.projects]);
  store.commit('setLastUpdated', response.lastUpdated || '')
  store.commit('setHighlightLastUpdated', !!response.highlightLastUpdated)
  store.commit('setDemoDomain', !!response.demoDomain)
  const isTrialDomain = !!response.trialDomain
  if (isTrialDomain) {
    store.commit('setTrialDomain', true)
    store.commit('setTrialLeftTime', response.trialLeftTime)
    store.commit('setTrialTimeProgress', response.trialTimeProgress)
  }

  store.commit('ticketsfilters/setSearchInDefault', response.model.config.searchFields)
  //store.commit('ticketsfilters/setSearchIn', response.model.config.searchFields)

  const { createLocale } = useLocale()
  const i18n = createLocale(response.localeParams.locale, response.localeParams.messages)

  if (window['disarea']['isDebugMode'] && response.localeParams.locale === 'ru_RU') {
    const messagesCopy = {...response.localeParams.messages[response.localeParams.locale]}
    const initial_i18n_t = i18n.global.t
    i18n.global.t = (stringKey) => {
      const valueInTranslationFile = messagesCopy[stringKey]
      let translateResult = initial_i18n_t(stringKey)
      if (!valueInTranslationFile) {
        translateResult = '%' + translateResult + '%'
      }
      return translateResult
    }
  }

  if (response.topMessages) {
    store.commit('setTopMessages', response.topMessages)
  }

  if (response.showFeedbackPopup) {
    store.commit('setNeedShowSmileFeedback', true)
  }

  const panelsConfigs = response.model.config.panels
  if (panelsConfigs) {
    const screensWithPanels = Object.keys(panelsConfigs)
    screensWithPanels.forEach(screenName => {
      const screenPanelConfig = panelsConfigs[screenName]
      try {
        store.commit(screenName + '/setResizablePanel', screenPanelConfig['resizable_panel'])
        store.commit(screenName + '/setResizablePanelWidth', screenPanelConfig['width'])
        store.commit(screenName + '/setLeftPanelMinWidth', screenPanelConfig['min_width'])
        store.commit(screenName + '/setRightPanelMinWidth', screenPanelConfig['min_right_width'])
      } catch (e) {
        console.log('main panelsConfig error', screenName, e)
      }
    })
  }

  app
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueTippy, {
      componentSingleton: 'tippy-singleton',
      defaultProps: {
        delay: 400,
        theme: 'dark',
        touch: ['hold', 500],
        //trigger: 'mouseenter click',
        onShown(instance) {
          console.log('VueTippy defaultProps onShown', instance)
        },
        onHide(instance) {
          console.log('VueTippy defaultProps onHide', instance)
        },
      }
    })
    .use(FloatingVue)
    //.use(CKEditor)
    .mount('#app');

  if (response.redirect) {
    console.log('Redirect testing 8', response.redirect)
    await router.push(response.redirect)
  }

  if (response.errorMessage) {
    await store.dispatch('addNotification', {text: response.errorMessage, type: 'error'})
  }

  //document.getElementById("sq_loader")!.style.display = 'none';


}).catch((e) => {
  console.log('main action error', e)
  if (window['disarea'] && window['disarea']['errorLogger'] && typeof window['disarea']['errorLogger']['logData'] === 'function') {
    window['disarea']['errorLogger']['logData']({'main action error': e.message})
  }
})


console.log('main', process.env);
//123