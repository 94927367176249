

import {computed, inject, onBeforeUnmount, onMounted, watch, watchEffect} from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import LayoutMain from '@/layout/LayoutMain.vue'
import {useAppUtils} from "@/composables/useAppUtils"
import {refreshScreen, autorefreshBlockAdd, autorefreshBlockRemove} from "@/composables/useFetchState"
import {useColorScheme} from "@/composables/useColorScheme"

export default {

  components: {
    LayoutMain
  },

  setup() {
    const route = useRoute()
    const store = useStore()
    const {
      restoreEvent,
      //getSystemColorScheme,
    } = useAppUtils()

    const {
      initAppTheme
    } = useColorScheme()

    initAppTheme()

/*
    const theme = computed(() => store.getters.theme === 'auto' ? null : store.getters.theme )
    const useColorSchemeMetaTag = true
    const systemColorSchemeQuery = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')

    const setColorSchemeMetaTag = (value = 'auto') => {
      console.log('TestTheme setColorSchemeMetaTag', value)
      // window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      if (systemColorSchemeQuery) {
        console.log('TestTheme removeEventListener')
        systemColorSchemeQuery.removeEventListener('change', systemColorSchemeListener)
      }
      if (useColorSchemeMetaTag) {
        if (value === 'auto') {
          value = systemColorSchemeQuery.matches ? 'dark' : 'light'
          console.log('TestTheme addEventListener', value)
          systemColorSchemeQuery.addEventListener('change', systemColorSchemeListener)
        }
        document.querySelectorAll('meta[name="color-scheme"]').forEach(node => {
          if (node.parentNode) {
            node.parentNode.removeChild(node);
          }
        })
        const colorSchemeMetaTag = document.createElement('meta')
        colorSchemeMetaTag.setAttribute('name', 'color-scheme')
        colorSchemeMetaTag.setAttribute('content', value)
        document.head.appendChild(colorSchemeMetaTag)
      }
    }

    const systemColorSchemeListener = (event) => {
      console.log('TestTheme systemColorSchemeListener', event.matches, event)
      //setColorSchemeMetaTag(event.matches ? 'dark' : 'light')
      const colorSchemeMetaTag = document.head.querySelector('meta[name="color-scheme"]')
      if (colorSchemeMetaTag) {
        colorSchemeMetaTag.setAttribute('content', event.matches ? 'dark' : 'light')
      }

    }

    const stopWatchingTheme = watchEffect(() => {
      console.log('TestTheme App.vue watchEffect', theme.value)
      if (theme.value) {
        document.documentElement.setAttribute('data-theme', theme.value)
        setColorSchemeMetaTag(theme.value)
      } else {
        document.documentElement.removeAttribute('data-theme')
        setColorSchemeMetaTag('auto')
      }
    })
*/
    // const stopWatchingDocumentTitle = watch(() => store.getters.htmlTitle, (value) => {
    //   document.title = value
    // })

    const emitter: any = inject('emitter');
    let restoreEventProgress = {}
    const restoreEventHandler = async (id) => {
      console.log('--- emitter event_restore', id, restoreEventProgress)
      if (restoreEventProgress[id]) {
        return false
      }
      restoreEventProgress[id] = true
      autorefreshBlockAdd('App_restoreEvent')
      const data = await restoreEvent(id)
      autorefreshBlockRemove('App_restoreEvent')
      console.log('emitter event_restore', id, data, restoreEventProgress)
      if (data && !data.isError && Object.keys(restoreEventProgress).length === 1) {
        //await fetchState()
        await refreshScreen()
      }
      delete restoreEventProgress[id]
    }

    onMounted(()=>{
      console.log('app mounted', process.env, process);
      restoreEventProgress = {}
      emitter.off("event_restore", restoreEventHandler);
      emitter.on("event_restore", restoreEventHandler);
    })

    onBeforeUnmount(() => {
      //stopWatchingTheme()
      emitter.off("event_restore", restoreEventHandler);
      //stopWatchingDocumentTitle()
    })

    return {
      layoutName: computed(() => route.meta.layout)
    }
  }

}
